
(function($, window, document) {
    $(function() {
        // Get language from url
        // var language = getParameterByName('locale'),
            // locale = "nl_NL";
            // locale = "be_FR";

        // var country = getCountryFromLocale(locale);

        $("[data-localize]").localize("laas", { loadBase: false, pathPrefix: "languages", language: locale, callback: localizeLoaded});
    });


    function localizeLoaded(data, defaultCallback) {
        // dispatch custom event to init application
        $(window).trigger('laas:initLightingSolutions', [data]);

        // execute default callback
        defaultCallback(data)
    }

    function normaliseLang (lang) {
        lang = lang.replace(/_/, '-').toLowerCase();
        if (lang.length > 3) {
            lang = lang.substring(0, 3) + lang.substring(3).toUpperCase();
        }
        return lang;
    }

    function getParameterByName(name, url) {
        if (!url) {
            url = window.location.href;
        }
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    function getCountryFromLocale(locale) {
        return locale.split('_')[0];
    }

}(window.jQuery, window, document));
