(function($, window, document) {
    var mailFormUrl = "https://s3002.t.eloqua.com/e/f2";
    var $form = $('#laa-email-form');
    var $body = $('body');
    var languagePath;


    if(locale === "be_FR") {
       languagePath = './languages/laas-be-FR.json';
      } else {
        languagePath = './languages/laas-nl-NL.json';
    }

  $.getJSON(languagePath, function(data){
      $form.validate({
        messages: {
          firstName: data.validate_firstname,
          lastName: data.validate_lastname,
          emailAddress: data.validate_emailaddress,
          company: data.validate_company,
          role: data.validate_role,
          termsOfUseAndPrivacyPolicy: data.validate_terms
        },
        errorLabelContainer: "#laa-error-container",
        wrapper: "li"
      });

    }).fail(function(data){
      console.log('Failed to get language file:');
      console.log(data)
    });

    $('.laa-card__mail-bar').click( function() {
        philips.analytics.trackConversion ({
          name:"interaction",
          description:"open_email_form"
        });


        var serverData = JSON.parse(Cookies.get('serverData'));
        var newOperationalCosts = JSON.parse(Cookies.get('newOperationalCosts'));

        var data = {};

        data.yearlysaving = Math.round(serverData.profit);
        // data.currentlightsolution = $(".laa-card__lighting-solution").val();
        data.currentlightsolution = $('.laa-card__lighting-solution option:selected').text();
        data.area = $('[name="surface"]').val();
        data.hoursperyear = $('[name="hours"]').val();
        data.shopsamount = $('[name="stores"]').val();
        data.currentcosts = serverData.operationalCosts;
        data.savings = serverData.savings;
        data.circularlightingrate = Math.round(serverData.laasTariff);
        data.profit = Math.round(serverData.profit);
        // data.campaign = "CircularLi"; //already hardcoded in template
        data.newcosts = newOperationalCosts;
        // data.ParentURL = window.parent.location; //doesn't work within an iframe, so hardcoded for now.
        data.ParentURL = "http://www.lighting.philips.nl/systemen/circular-lighting/circular-rekentool";
        data.locale = locale;

        initializeForm(data);
    });


    $('#laa-submit-email-form-decoy').click(function(){
      if($form.valid()) {
        $('#laa-submit-email-form').click();

        //extra tracking for email optin
        if ($('[name="Optinq"]').val() === "No") {
          philips.analytics.trackAjax({
            pagename: "opt_in",
            type: "o"
          });
        } else {
          philips.analytics.trackAjax({
            pagename: "opt_in",
            type: "o"
          });
        }

        philips.analytics.trackConversion({
          name: "user_registration",
          type: "o"
        });

        $body.removeClass('laa--email-visible');
        $body.addClass('laa--transition--fade');
        $body.addClass('laa--confirmation-visible');
      }
    });


    function initializeForm(data){
        for(var item in data) {
            $('#hidden-' + item).val(data[item]);
        }
    }
  
}(window.jQuery, window, document));