(function($, window, document) {
    $('.laa-cta').click(function(){
        philips.analytics.trackConversion ({
            name:"interaction",
            description:"contact"
        });

        // top.location = top.location.origin + top.location.pathname + '#laas_retail_campagne_form';
        top.location = 'production-page.html' + '#laas_retail_campagne_form';
    });
}(window.jQuery, window, document));