(function($, window, document) {

    $('.laa-confirmation__back').click( function(){
        $('body').removeClass('laa--confirmation-visible');

      philips.analytics.trackConversion ({
        name:"interaction",
        description:"cancel_email_calculation"
      });
    });
    
}(window.jQuery, window, document));